/* eslint-disable react/jsx-no-useless-fragment */
import { ReactNode } from 'react';
import { NextRouter, useRouter } from 'next/router';
import { signOut, useSession } from 'next-auth/react';

import { useFeatureList, useFeatureToggle } from '_src/app/feature_toggle';
import {
  RelatedUser,
  useGetRelatedUsers,
} from '_src/app/user_switch/data/useGetRelatedUsers';
import { useSwitchUser } from '_src/app/user_switch/data/useSwitchUser';
import { useLogServerSideError } from '_src/contexts/log/logger';
import { LogLevel } from '_src/__codegen__/types';

type FeatureReroutingProps = {
  children: ReactNode;
};

export const FeatureRerouting = ({ children }: FeatureReroutingProps) => {
  const router = useRouter();
  const serverLog = useLogServerSideError();
  let callbackUrl = (router.query?.callbackUrl as string) ?? '/home';
  if (callbackUrl === '/') {
    callbackUrl = '/home';
  }

  const { isLoading } = useFeatureList();
  const canSwitchUser = useFeatureToggle('terra-user-switch');
  const {
    users,
    loading: usersLoading,
    error: usersError,
  } = useGetRelatedUsers();

  const switchUser = useSwitchUser();
  const { status, data } = useSession();

  if (status === 'unauthenticated') {
    return <>{children}</>;
  }

  if (data?.error === 'RefreshAccessTokenError') {
    signOut();
    return null;
  }

  if (isLoading || usersLoading || !router) {
    return null;
  }

  if (users.length === 0 || usersError) {
    goToAccountNotFound(router);
    return <>{children}</>;
  }

  const loggedInUser = data ? getLoggedInUser(data.user.id, users) : null;
  if (!loggedInUser) {
    if (users.length > 1) {
      goToUserSwitch(router, callbackUrl);
    } else {
      switchUser(users[0].user_relation_id);
    }
    return <>{children}</>;
  }

  // Default the landing page / home screen to the first nav item
  if (router.pathname === '/') {
    if (users.length > 1 && canSwitchUser) {
      goToUserSwitch(router, callbackUrl);
    } else {
      serverLog(LogLevel.Error, `User not found id: ${data?.user.id}`);
      router.replace(callbackUrl || '/account-not-found');
    }
  }

  return <>{children}</>;
};

const goToUserSwitch = (router: NextRouter, callbackUrl: string) => {
  if (router.pathname !== '/user-switch') {
    router.replace(`user-switch?callbackUrl=${callbackUrl}`);
  }
};

const goToAccountNotFound = (router: NextRouter) => {
  if (router.pathname !== '/account-not-found') {
    router.replace('account-not-found');
  }
};

const getLoggedInUser = (userId: string, users: Array<RelatedUser>) =>
  users.find((u) => u.user_relation_id === userId);
