var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"917f4cad95819629c92bfea705b7fadb63c6971a"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const ENV = process.env.NEXT_PUBLIC_VERCEL_ENVIRONMENT || 'local';
const API_HOST = process.env.NEXT_PUBLIC_TERRA_GRAPHQL_URI || 'local';
const serverErrorsRegex = /401 Unauthorized|Received status code 401/im; // ignore 401 as they are intended
const uuidRegex =
  /([a-f0-9]{8}(-[a-f0-9]{4}){3}-[a-f0-9]{12})|([a-f0-9]{32})|({[a-f0-9]{6}-[a-f0-9]{2}-[a-f0-9]{2}-[a-f0-9]{2}-[a-f0-9]{6}})/gi;

Sentry.init({
  environment: ENV,
  dsn:
    SENTRY_DSN ||
    'https://7e585b26e53a450b9c7d9da47aafcfb5@o448303.ingest.sentry.io/6263879',
  tracesSampleRate: 0.2,
  ignoreErrors: [
    serverErrorsRegex,
    'ResizeObserver loop limit exceeded',
    'ResizeObserver loop completed with undelivered notifications',
    'TypeError: Load failed',
    'ApolloError: Load failed',
    'speed-insights',
  ],
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [API_HOST],
      // Scrub id's from transaction names
      beforeNavigate: (context) => ({
        ...context,
        // Replace any id. eg accountId with placeholder
        name: window.location.pathname.replace(uuidRegex, '<redacted>'),
      }),
    }),
  ],
  // Scrub id's from breadcrumb paths
  beforeBreadcrumb(breadcrumb) {
    if (
      breadcrumb &&
      breadcrumb.category === 'navigation' &&
      breadcrumb.data != null
    ) {
      Object.keys(breadcrumb.data).forEach((key) => {
        if (breadcrumb.data && typeof breadcrumb.data[key] === 'string') {
          // eslint-disable-next-line no-param-reassign
          breadcrumb.data[key] = breadcrumb.data[key].replace(
            uuidRegex,
            '<redacted>'
          );
        }
      });
    }
    return breadcrumb;
  },
});
